import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useResetPasswordSetting() {
  // Use toast
  const toast = useToast()

  const sendResetPasswordEmail = queryParams => axios
    .post('/password/email', queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'The Reset Password Email was successfully send',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data.data
    })

  const resetPassword = queryParams => axios
    .post('/password/reset', queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'The Password was successfully updated',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data.data
    })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    sendResetPasswordEmail,
    resetPassword,
  }
}
