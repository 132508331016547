<template>
    <div class="login site__centered">

        <form
                class="form"
                @submit.prevent="submitForgotPasswordForm"
        >
            <div class="form__wrap login__form">
                <h2 class="form__title"> Forgot Password?</h2>
                <p class="form__subtitle">Enter your email and we'll send you instructions to reset your password</p>
                <label class="form__row ">
                    <span class="form__row-label">Email *</span>
                    <input
                            v-model="formData.userEmail"
                            type="text"
                            placeholder="username"
                            :class="{'is-invalid': $v.formData.userEmail.$dirty &&
                            ( !$v.formData.userEmail.required || !$v.formData.userEmail.email) }"
                    />
                </label>

              <div
                v-if="validationErrors.length"
                class="form-group"
              >
                <p
                  v-for="(validationError, index) in validationErrors"
                  :key="index"
                  class="text-center form__error"
                >
                  {{ validationError }}
                </p>
              </div>

                <button
                  class="btn btn--color-dark form__button"
                  :class="{ 'btn--loading': isLoading }"
                >
                  <span v-if="!isLoading">Send Reset Link</span>
                </button>
                <span class="center">Or</span>
                <router-link
                    :to="{name: 'login'}"
                    class="btn btn--color-outline-bright form__button"
                >Back to login</router-link>

            </div>
        </form>

        <!-- <welcome-block class="mobile-hidden"/> -->
    </div>
</template>

<script>
  import { required, email } from 'vuelidate/lib/validators'

  import useResetPasswordSetting from '@/views/useResetPasswordSetting'

  export default {
    data() {
      return {
        formData: {
          userEmail: null,
        },
        isLoading: false,
        validationErrors: []
      }
    },
    validations: {
      formData: {
        userEmail: { required, email },
      }
    },
    setup() {
      const {
        sendResetPasswordEmail,
      } = useResetPasswordSetting()

      return {
        sendResetPasswordEmail,
      }
    },
    methods: {
      async submitForgotPasswordForm() {
        if (this.$v.$invalid){
          await this.$v.$touch();
          document.querySelector('.is-invalid').focus()
          this.errorMessage = 'All fields are required'
          return
        }

        this.isLoading = true

        this.sendResetPasswordEmail({ email: this.formData.userEmail })
          .then(() => {
            this.validationErrors = []
          })
          .catch(error => {
            if (error.response.status === 422) {
              const validationErrors = Object.values(error.response.data.errors)
              this.validationErrors = validationErrors.flat()
            } else {
              console.log(error)
            }
          })
          .finally(() => {
            this.isLoading = false
          })
      },
    },
  }
</script>

<style>
</style>
